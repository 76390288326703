import React, { useEffect } from 'react';

export const PaginaPolicasApp = () => {
    useEffect(() => {
        // Redireciona automaticamente para o PDF
        window.location.href = './plannerapp.pdf';
    }, []);

    return (
        <div style={{
            flexDirection: 'row',
            backgroundColor: '#1E1E1E',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white'
        }}>
            {/* Link acessível para abrir o PDF */}
            <a 
                href="URL_DO_PDF" 
                target="_blank" 
                rel="noopener noreferrer" 
                style={{ color: '#00BFFF', textDecoration: 'underline' }}
            >
                Clique aqui para visualizar as Políticas do App
            </a>
        </div>
    );
};
