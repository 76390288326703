import React from 'react';
import logo from './capa.png';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { PaginaPolicasApp } from './policasApp';

function App() {
  return (
    <>
      <Routes>
        <Route path="/politicas" element={<PaginaPolicasApp />} />
      </Routes>
      <div style={{
        flexDirection: 'row',
        backgroundColor: '#1E1E1E',
        width: '100%',
        height: '100%',
      }}>
        <div>
          <h2 className='logo'>Anti-Falhas</h2>
          <div className='error-container'>
            <h2 className='error'>
              404
            </h2>
            <h4 className='not-found'>
              Página não encontrada
            </h4>
            <p className='not-found-description'>
              Em breve estaremos disponíveis
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;